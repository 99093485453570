import request from '@/utils/request.js'

// 查询任务列表
export const QueryCronList = (data) => {
  return request({
    method: 'GET',
    url: 'cron',
    params: {
      ...data
    }
  })
}

// 通过名称查找任务
export const SearchCron = (data) => {
  return request({
    method: 'GET',
    url: 'cron',
    params: {
      ...data
    }
  })
}

// 添加任务
export const AddCron = (data) => {
  return request({
    method: 'POST',
    url: 'cron',
    data
  })
}

// 编辑任务
export const ModifyCron = (data) => {
  return request({
    method: 'PUT',
    url: 'cron',
    data
  })
}

// 删除任务
export const DelCron = (data) => {
  return request({
    method: 'DELETE',
    url: 'cron',
    data
  })
}
