<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>项目管理</el-breadcrumb-item>
      <el-breadcrumb-item>定时任务</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div class="cron-header" slot="header">
        <div>
          <el-button type="warning" icon="el-icon-caret-right" @click="handleAdd">新增任务</el-button>
        </div>
        <el-form :inline="true" :model="cronForm" class="demo-form-inline">
          <el-form-item>
            <el-input v-model="cronForm.cronName" placeholder="输入任务名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%">
        <el-table-column
        label="序号"
        type="index"
        width="50">
        </el-table-column>
        <el-table-column
          prop="cron_id"
          label="任务ID"
          width="180">
        </el-table-column>
        <el-table-column
          prop="cron_name"
          label="任务名称"
          width="180">
        </el-table-column>
        <!-- <el-table-column prop="func_data.run_content" label="执行内容">
          <template slot-scope="scope">
            {{ scope.row.func_data.run_content.join(',') }}
          </template>
        </el-table-column> -->
        <el-table-column
          prop="next_run_time"
          label="执行时间">
        </el-table-column>
        <el-table-column prop="cron_status" label="任务状态">
          <template slot-scope="scope">
            <el-tag :type="{'0':'danger','1':'success'}[scope.row.cron_status]" effect="light">
              {{ scope.row.cron_status === '0' ? '待执行' :'已完成' }}
              <!-- {{ {'0':'待执行','1':'已完成'}[scope.row.cron_status] }} -->
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="260">
        <template slot-scope="scope">
          <el-button round type="warning" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button round type="danger" @click="handleDel(scope.row)">删除</el-button>
          <!-- <el-button round type="info" @click="handleDis(scope.row)">{{ scope.row.status === '1' ? '禁用' : '启用' }}</el-button> -->
        </template>
      </el-table-column>
      </el-table>
    </el-card>

    <!-- 环境列表分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.currentPage"
      :page-sizes="[1, 10, 30, 50]"
      :page-size="pageInfo.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInfo.total_count">
    </el-pagination>

    <!-- 新增环境变量 dialog -->
    <el-dialog title="任务信息" :visible.sync="dialogFormVisible" :label-width="formLabelWidth">
      <el-form :model="dialogCronForm" ref="dialogCronFormRef">
        <el-form-item  v-if="!isAdd" label="编号" :label-width="formLabelWidth">
          <el-input v-model="dialogCronForm.cron_id" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="任务名称" :label-width="formLabelWidth">
          <el-input v-model="dialogCronForm.cron_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="是否发送邮件" :label-width="formLabelWidth">
          <el-switch v-model="dialogCronForm.is_email" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item v-if="dialogCronForm.is_email === '1'" label="发送邮件选择" :label-width="formLabelWidth">
          <el-select v-model="dialogCronForm.send_email_id" placeholder="请选择发送邮件配置">
            <el-option label="发送1" value=1></el-option>
            <el-option label="发送2" value=2></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务类型" :label-width="formLabelWidth">
          <el-select v-model="dialogCronForm.cronTab.cronType" placeholder="请选择任务类型">
            <el-option label="间隔时间任务" value="interval"></el-option>
            <el-option label="指定日期任务" value="date"></el-option>
            <el-option label="crontab命令" value="cron"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="dialogCronForm.cronTab.cronType === 'interval'" label="执行间隔(s)" :label-width="formLabelWidth">
          <el-input v-model="dialogCronForm.cronTab.interval_list.seconds" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="dialogCronForm.cronTab.cronType === 'date'" label="执行时间" :label-width="formLabelWidth">
          <el-input v-model="dialogCronForm.cronTab.date_list.run_date" autocomplete="off" placeholder="20XX-04-01 12:05:35"></el-input>
        </el-form-item>
        <el-form-item v-if="dialogCronForm.cronTab.cronType === 'date'" label="时区" :label-width="formLabelWidth">
          <el-input v-model="dialogCronForm.cronTab.date_list.timezone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="dialogCronForm.cronTab.cronType === 'cron'" label="crontab表达式" :label-width="formLabelWidth">
          <el-input v-model="dialogCronForm.cronTab.cron_list.cron_exp" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="任务处理器" :label-width="formLabelWidth">
          <el-select v-model="dialogCronForm.func" placeholder="请选择任务处理器">
            <el-option label="接口自动化1.0.0(废弃)" value="ExecuteTestCaseController.execute_testsuite"></el-option>
            <el-option label="接口自动化2.0.0" value="ApiCaseRunController.api_run_testcase"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="执行内容" :label-width="formLabelWidth">
          <el-input v-model="dialogCronForm.func_data.run_content" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="执行方式" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="dialogCronForm.func_data.executionMode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="dialogCronForm.func === 'ExecuteTestCaseController.execute_testsuite'"  label="所属项目ID" :label-width="formLabelWidth">
          <el-input v-model="dialogCronForm.func_data.project_id" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="dialogCronForm.func === 'ExecuteTestCaseController.execute_testsuite'"  label="所属项目名称" :label-width="formLabelWidth">
          <el-input v-model="dialogCronForm.func_data.project_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="所属环境" :label-width="formLabelWidth">
          <el-input v-model="dialogCronForm.func_data.env_id" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="isAdd" type="primary" @click="processAdd">新 增</el-button>
        <el-button v-if="!isAdd" type="primary" @click="processEdit">编 辑</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { QueryCronList, AddCron, SearchCron, ModifyCron, DelCron } from '@/services/cron.js'
export default {
  name: 'Cron',
  data () {
    return {
      cronForm: {
        cronName: ''
      },
      tableData: [],
      pageInfo: {
        size: 10,
        currentPage: 1,
        total_count: null
      },
      dialogFormVisible: false,
      dialogCronForm: {
        cron_name: '',
        is_email: '',
        send_email_id: '',
        func: '',
        func_data: {},
        cronTab: {
          cronType: 'date',
          interval_list: {
            weeks: '',
            days: '',
            hours: '',
            minutes: '',
            seconds: '360',
            start_date: '',
            end_date: '',
            timezone: ''
          },
          date_list: {
            run_date: '2026-09-08 16:00:00',
            timezone: 'UTC-8'
          },
          cron_list: {
            cron_exp: '*/3 * * * *',
            year: '',
            month: '',
            day: '',
            week: '',
            day_of_week: '',
            hour: '',
            minute: '',
            second: '',
            start_date: '',
            end_date: '',
            timezone: ''
          }
        }
      },
      isAdd: true,
      formLabelWidth: '100px'
    }
  },
  created () {
    this.loadCronList()
  },
  methods: {
    async loadCronList () {
      const queryObj = {
        query_type: 'query_list',
        size: this.pageInfo.size,
        page: this.pageInfo.currentPage
      }
      const { data: res } = await QueryCronList(queryObj)
      if (res.status === 1) {
        this.tableData = res.data
        this.pageInfo.total_count = res.total_count
      }
    },
    handleAdd () {
      this.isAdd = true
      this.dialogFormVisible = true
      this.dialogCronForm = {
        cron_name: '',
        is_email: '',
        send_email_id: '',
        func: '',
        func_data: {
          project_id: '',
          project_name: '',
          env_id: '',
          run_content: '',
          executionMode: ''
        },
        cronTab: {
          cronType: 'date',
          interval_list: {
            weeks: '',
            days: '',
            hours: '',
            minutes: '',
            seconds: '360',
            start_date: '',
            end_date: '',
            timezone: ''
          },
          date_list: {
            run_date: '2026-09-08 16:00:00',
            timezone: 'UTC-8'
          },
          cron_list: {
            cron_exp: '*/3 * * * *',
            year: '',
            month: '',
            day: '',
            week: '',
            day_of_week: '',
            hour: '',
            minute: '',
            second: '',
            start_date: '',
            end_date: '',
            timezone: ''
          }
        }
      }
    },
    async handleSearch () {
      const searchObj = {
        query_type: 'search_cron',
        cron_name: this.cronForm.cronName
      }
      const { data: res } = await SearchCron(searchObj)
      if (res.status === 1) {
        this.tableData = res.data
      }
    },
    handleEdit (rowData) {
      console.log('任务列表数据：', rowData)
      this.isAdd = false
      this.dialogFormVisible = true

      const funcDataObj = { ...rowData.func_data }
      if (Array.isArray(rowData.func_data.run_content) && rowData.func_data.run_content) {
        funcDataObj.run_content = funcDataObj.run_content.join(',')
      }

      this.dialogCronForm = {
        cron_id: rowData.cron_id,
        cron_name: rowData.cron_name,
        is_email: rowData.is_email,
        send_email_id: rowData.send_email_id,
        cron_status: rowData.cron_status,
        func: rowData.cron_func,
        func_data: funcDataObj,
        cronTab: {
          cronType: rowData.cron_type,
          interval_list: {
            weeks: '',
            days: '',
            hours: '',
            minutes: '',
            seconds: rowData.trigger.interval_time,
            start_date: rowData.trigger.start_date,
            end_date: '',
            timezone: rowData.trigger.timezone
          },
          date_list: {
            run_date: rowData.trigger.run_date,
            timezone: rowData.trigger.timezone
          },
          cron_list: {
            cron_exp: '*/3 * * * *',
            year: '',
            month: '',
            day: '',
            week: '',
            day_of_week: '',
            hour: '',
            minute: '',
            second: '',
            start_date: '',
            end_date: '',
            timezone: rowData.trigger.timezone
          }
        }
      }
      console.log('任务列表数据2：', funcDataObj)
      console.log('任务列表数据3：', this.dialogCronForm)
    },
    handleDel (rowData) {
      this.$confirm(
        '此操作将永久删除该数据, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '关闭',
          type: 'warning'
        }
      ).then(async () => {
        const delObj = { cron_id: rowData.cron_id }
        const { data: res } = await DelCron(delObj)
        if (res.status === 1) {
          this.$message.success('删除任务成功')
          this.loadCronList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async processAdd () {
      try {
        const AddCronObj = JSON.parse(JSON.stringify(this.dialogCronForm));
        if (this.dialogCronForm.func === 'ExecuteTestCaseController.execute_testsuite') {
          AddCronObj.func_data.run_content = AddCronObj.func_data.run_content.split(',')
        }
        if (this.dialogCronForm.func === 'ApiCaseRunController.api_run_testcase') {
          AddCronObj.func_data.run_content = AddCronObj.func_data.run_content.split(',')
        }
        const { data: res } = await AddCron(AddCronObj)
        console.log(res, '<---添加定时任务信息')
        if (res.status === 1) {
          this.dialogFormVisible = false
          this.loadCronList()
          this.$message.success('创建任务成功')
        } else {
          this.$message.error(res.data)
        }
      } catch (error) {
        this.$message.error('请求错误，重新添加')
      }
    },
    async processEdit () {
      const editObj = JSON.parse(JSON.stringify(this.dialogCronForm));
      if (this.dialogCronForm.func === 'ExecuteTestCaseController.execute_testsuite') {
        editObj.func_data.run_content = editObj.func_data.run_content.split(',')
      }
      if (this.dialogCronForm.func === 'ApiCaseRunController.api_run_testcase') {
        editObj.func_data.run_content = editObj.func_data.run_content.split(',')
      }
      const { data: res } = await ModifyCron(editObj)
      if (res.status === 1) {
        this.dialogFormVisible = false
        this.$message.success('编辑测试用例成功')
        this.loadCronList()
      }
    },
    // 环境信息 分页处理
    handleSizeChange (val) {
      this.pageInfo.size = val
      this.loadCronList()
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.pageInfo.currentPage = val
      this.loadCronList()
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.cron-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.demo-form-inline{
  height: 40px;
}
.el-pagination {
    padding: 0px 0;
  }
</style>
